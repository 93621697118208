import React from "react"
import {Link} from "gatsby"
import Layout from "../layouts/default"
import SEO from "../components/SEO"

const NotFoundPage = () => (
  <Layout noHeaderImage={true}>
    <SEO title="404: Not found" />
    <div className="container mt-5 pt-5 text-center">
        <h1>Not Found</h1>
        <p>The page you are looking for doesn&#39;t exist... the sadness.</p>
        <p><Link to="/" className="btn btn-icl">Return Home</Link></p>
    </div>
  </Layout>
)

export default NotFoundPage
